<template>
	<div class="product-card">
		<div class="card-content">
			<div class="left-content">
				<div
					v-for="(image, index) in images"
					:key="`product-image-${index}`"
				>
					<ImageResponsive
						v-if="selectedColorIndex === index"
						v-bind="image.productImage"
						class="image"
					/>
				</div>
				<div
					v-show="images && images.length > 1"
					class="color-container"
				>
					<button
						v-for="(image, index) in images"
						:key="`color-${index}`"
						class="color"
						:class="{ selected: selectedColorIndex === index }"
						:style="`background-color:${image.colorCode}`"
						:title="image.colorName"
						@click="selectColor(index)"
					></button>
				</div>
			</div>

			<div class="right-content">
				<TnHeading
					v-if="title"
					type="h3"
					size="xl"
					class="title"
					v-editable-string
					>{{ title }}</TnHeading
				>

				<BulletList
					v-if="cardList && cardList.length > 0"
					:showBottomRuler="false"
					:hideTopRuler="true"
					:blackCheckmarks="true"
					:bulletList="cardList"
					:numVisibleItems="5"
				/>

				<div
					v-if="card.paymentPlans"
					class="payment-options"
				>
					<div
						v-for="option in card.paymentPlans"
						:key="`payment-option-${option.title}`"
						class="option"
					>
						<div class="option-title">{{ option.title }}</div>
						<div class="option-price">{{ option.price }}</div>
					</div>
				</div>
			</div>
		</div>

		<LinkInline
			v-if="link && link.text"
			v-bind="link"
			size="m"
			chevron-right
			class="margin-top-l"
			editable
		/>
	</div>
</template>

<script>
import ResponsiveImageViewModel from "../../../../../helpers/ViewModels/ResponsiveImageViewModel.js";
import LinkViewModel from "../../../../../helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "ProductCard",

	props: {
		card: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			selectedColorIndex: 0,
		};
	},

	computed: {
		images() {
			let productImages = this.card && this.card.colors;
			if (productImages) {
				productImages = JSON.parse(JSON.stringify(productImages));
				return (
					productImages &&
					productImages.map((color) => {
						color.productImage = color.productImage && ResponsiveImageViewModel(color.productImage);
						return color;
					})
				);
			}
			return [];
		},
		title() {
			return this.card.productName;
		},
		link() {
			return this.card.buttonLink && LinkViewModel(this.card.buttonLink);
		},
		blackText() {
			return this.card.buttonLink && this.card.buttonLink.white;
		},
		cardList() {
			return (
				this.card.usps &&
				this.card.usps.map((listItem) => {
					listItem = { listPoint: listItem };
					return listItem;
				})
			);
		},
	},

	methods: {
		selectColor(index) {
			this.selectedColorIndex = index;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "sass:math";

.product-card {
	display: flex;
	display: -ms-flex;
	flex: 1 1 calc(50% - 48px);
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	min-width: 400px;
	max-width: 585px;
	width: 100%;
	height: auto;
	margin: 0 math.div($spacing-l, 2) $spacing-l;
	border-radius: 7px;
	transition:
		transform 0.1s linear,
		box-shadow 0.1s linear;
	background-color: $color-neutrals-25-tint;
	padding: $spacing-2xl $spacing-2xl $spacing-xl;
	box-sizing: border-box;

	.card-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		text-align: left;

		.left-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;

			.image {
				width: 100%;
				height: auto;
				min-height: 225px;
				min-width: 155px;
				padding-right: $spacing-l;
				padding-left: $spacing-l;
				padding-bottom: $spacing-m;

				:deep(img) {
					width: 150px;
					max-width: 150px;
					border-radius: 7px 7px 0 0;
					object-fit: cover;
				}

				@include breakpoint(mobile) {
					margin-right: $spacing-s;
					margin-bottom: 0;
				}
			}

			.color-container {
				width: 150px;
				height: max-content;
				display: block;
				text-align: center;
				padding: 0 $spacing-s;

				.color {
					width: $spacing-l;
					height: $spacing-l;
					display: inline-block;
					border-radius: 50%;
					outline: none;
					border: 2px solid transparent;
					position: relative;
					margin: $spacing-xs;
					padding: 0;

					&.selected {
						border: 2px solid $color-cta-default;
					}

					&::before {
						width: 200%;
						height: 200%;
						content: "";
						display: block;
						position: absolute;
						top: -75%;
						left: 0;
						background-image: radial-gradient(hsl(0deg 0% 100% / 40%) 10%, hsl(0deg 0% 100% / 0%) 75%);
					}

					@include breakpoint(mobile) {
						display: block;
					}
				}

				@include breakpoint(mobile) {
					display: block;
					width: max-content;
					padding: 0;
				}
			}

			@include breakpoint(mobile) {
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-bottom: $spacing-l;
			}
		}

		.right-content {
			width: 60%;

			:deep(.bullet-list) {
				padding-left: 0;
				margin-bottom: $spacing-2xl;

				li {
					margin-bottom: $spacing-m;
				}

				@include breakpoint(mobile) {
					padding-left: $spacing-l;
				}
			}

			.payment-options {
				.option {
					font-weight: bold;
					margin-bottom: $spacing-m;
					margin-right: $spacing-m;

					.option-title {
						@include font-text-2xs;
					}

					.option-price {
						@include font-text-m;
					}
				}

				@include breakpoint(mobile) {
					display: flex;
					justify-content: space-evenly;
				}
			}

			@include breakpoint(mobile) {
				width: auto;

				h3 {
					text-align: center;
				}
			}
		}

		@include breakpoint(mobile) {
			flex-direction: column;
			align-items: center;
		}
	}

	> *:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.title {
		margin-bottom: $spacing-m;
	}

	@media screen and (width >= 0\0) {
		/* IE9, IE10, IE11 */
		display: block;
	}

	@include breakpoint(mobile) {
		min-width: 345px;
		max-width: 100%;
	}
}
</style>
