<template>
	<div class="bullet-list padding-horizontal-xl margin-bottom-l">
		<TnDivider
			v-if="!hideTopRuler"
			size="s"
			width="full"
		/>

		<ul class="padding-horizontal-m margin-vertical-s">
			<TnTransitionHeight
				v-for="(point, index) in bulletList"
				:key="`point-${index}`"
				:showIf="index < numVisibleItems || displayAll"
			>
				<li :class="{ inactive: point.inactive, 'black-checkmarks': blackCheckmarks }">
					<span class="bold">{{ point.listPoint }}</span>
					<TnTransitionHeight :showIf="displayAll">
						<span>{{ point.description }}</span>
					</TnTransitionHeight>
				</li>
			</TnTransitionHeight>
		</ul>

		<button
			v-if="useDisplayAll"
			class="toggle-all"
			tabindex="0"
			@click="toggleDisplayAll"
			@keyup.enter="toggleDisplayAll"
		>
			{{ displayAll ? "Vis mindre" : "Vis mer" }}
			<span class="chevron">
				<TnIcon
					v-if="displayAll"
					name="chevron-up"
				/>
				<TnIcon
					v-else
					name="chevron-down"
				/>
			</span>
		</button>

		<TnDivider
			v-if="showBottomRuler"
			size="s"
			width="full"
		/>
	</div>
</template>

<script>
export default defineNuxtComponent({
	props: {
		bulletList: {
			type: Array,
		},
		showBottomRuler: {
			type: Boolean,
		},
		blackCheckmarks: {
			type: Boolean,
			default: false,
		},
		hideTopRuler: {
			type: Boolean,
			default: false,
		},
		numVisibleItems: {
			type: Number,
			default: 3,
		},
	},

	data() {
		return {
			displayAll: false,
		};
	},

	computed: {
		useDisplayAll() {
			function hasDescription(item) {
				return item.description;
			}
			return this.bulletList.length > this.numVisibleItems || this.bulletList.some(hasDescription);
		},
	},

	methods: {
		toggleDisplayAll(e) {
			e.preventDefault();
			this.displayAll = !this.displayAll;
		},
	},
});
</script>

<style lang="scss" scoped>
.bullet-list {
	hr {
		margin: 0;
	}

	ul {
		text-align: left;

		@include font-text-s;

		div:not(:last-child) {
			margin-bottom: $spacing-s;
		}

		li {
			list-style-type: none;
			position: relative;
			padding-left: $spacing-s;

			&::before {
				width: $spacing-s;
				height: $spacing-xs;
				content: "";
				position: absolute;
				display: inline-block;
				margin-left: -$spacing-m;
				border: solid $color-information-500-core;
				border-width: 0 0 1px 1px;
				top: 10px;
				transform: rotate(-48deg);
			}

			&.black-checkmarks {
				margin-bottom: $spacing-s;

				&::before {
					width: 15px;
					height: 7px;
					content: "";
					position: absolute;
					display: inline-block;
					margin-left: -$spacing-l;
					border: solid $color-neutrals-black;
					border-width: 0 0 1px 1px;
					top: 5px;
					transform: rotate(-48deg);
				}
			}

			&.inactive {
				color: $color-neutrals-600-shade;
				font-style: italic;

				&::before,
				&::after {
					width: 2px;
					height: 10px;
					content: "";
					position: absolute;
					margin-left: -14px;
					top: 7px;
					border: none;
					background-color: $color-neutrals-600-shade;
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}
			}

			span {
				display: block;
			}

			.bold {
				font-weight: bold;
			}
		}
	}

	.toggle-all {
		display: inline-block;
		height: 30px;
		text-decoration: none;
		color: $color-text;
		border: none;
		outline: none;
		background-color: transparent;
		cursor: pointer;

		@include font-text-s;

		.chevron {
			svg {
				transform: translateY(7px);
			}
		}

		+ hr {
			margin-top: $spacing-l;
		}
	}
}
</style>
